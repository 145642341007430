import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import _get from 'lodash/get';

import Layout from '@components/Layout';
import Images from '@components/Images';
import YoutubeVideo from '@components/YoutubeVideo';

import { linkResolver } from '@utils/Prismic/linkResolver';
import { extractGraphqlData, extractYoutubeVideoUrl } from '@utils/extractors';
import { ENUM_STATIC_MENU_LINKS } from '@utils/types';

interface ICompanyProps {
  data: any;
}

const Company: React.FC<ICompanyProps> = ({ data }) => {
  const doc = extractGraphqlData(data, 'allCompany_pages');
  if (!doc) return null;

  const { page_title, page_description, canonical_tag, page_og_image } = doc.node.seo[0];

  const video = doc.node.bottom_video;
  const isVideo = video && video.provider_name==='YouTube';

  return (
    <>
      <Layout
        title={page_title ? RichText.asText(page_title) : 'Company'}
        description={page_description && RichText.asText(page_description)}
        firstLevelPage
        seo={{
          path: ENUM_STATIC_MENU_LINKS.COMPANY,
          canonical: canonical_tag && canonical_tag.url,
          image: page_og_image ? page_og_image : doc.node.images[0].image.url,
        }}
      >
        <div className="company">
          <div className={ isVideo ?  "company__text" : "company__text" }>
            <div className="anim-title">
              <h1>{RichText.asText(doc.node.heading)}</h1>
            </div>
            <div className="anim-block">
              {RichText.render(doc.node.body_text, linkResolver)}
            </div>            
            { isVideo &&
              <div className="company__video_panoramic anim-block">
                <YoutubeVideo url={extractYoutubeVideoUrl(video)} />
              </div>
            }
          </div>          
          <div className="company__images">
            <Images
              imageData={doc.node.images}
              extractPath="image"
              alt=""
              classes={{
                base: 'anim-image',
                extend: 'company',
                wrap: 'company__image',
                class: ''
              }}
              width="526"
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query Company {
    prismic {
      allCompany_pages {
        edges {
          node {
            heading
            body_text
            images {
              image              
            }
            bottom_video
            seo {
              page_title
              page_description
              page_og_image
              canonical_tag {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default Company;
