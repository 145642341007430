import React from 'react';
import Iframe from 'react-iframe';

interface IYoutubeVideoProps {  
  url: string;
}

const YoutubeVideo: React.FC<IYoutubeVideoProps> = ( { url }) => {  
  return (    
    <Iframe url={url} frameBorder={0} allow="web-share" allowFullScreen className='yt_video'>    
    </Iframe>
  );
}

export default YoutubeVideo;